@m-color-hover: #014e9c;
@m-color-hover-light: lighten(@m-color-hover, 10%);
@m-color-hover-dark: darken(@m-color-hover, 10%);

@replay-color: #3c5569;
@replay-bg-color: #c1e7fc;
@replay-bg-border-color: #a6ccde;

.img-thumbnail {
  padding:0;
  border:none;
  border-radius: 0;
}

.navbar-m {
  min-height: 0;
  margin-bottom: 0;
  background-color: #014e9c;
  background-repeat: repeat-x;
  border: 0;
  border-radius: 0;
  .navbar-collapse {
    ul {
      li{
        background-color: #014e9c;
        color: white;
        //&.m_nav_li_bg {
        //  background-image: url("/d-media/images/nav-split-line.png");
        //  background-repeat: no-repeat;
        //  background-position: right center;
        //}
        a {
          &:hover, &:active {
            color: #fff;
            background-color: #16466f;
          }
        }
        .level-1 {
          color:white;
          background-image: url("/d-media/images/nav-split-line.png");
          background-position: right center;
          background-repeat: no-repeat;
          @media (max-width: @screen-xs-max) {
            background-image: none;
          }
        }
      }
    }
    &.collapsing, &.in {
      background-color: transparent;
      background-image: none;
      background-repeat: repeat;
      ul {
        li {
          &.m_nav_li_bg {
            background:none;
            border-bottom: 1px solid @m-color-hover-dark;
          }
        }
      }
    }
  }
}
.nav-wrap {
  border-top: 1px solid #8fdbe9;
}
.top-wrap {
  .navbar-toggle {
    padding:10px;
    margin-top:12px;
    border-color: @m-color-hover;
    border-radius: 50%;
    &:hover{
      background-color: transparent;
    }
    .icon-bar {
      background-color: @m-color-hover-light;
      width: 12px;
      height:1px;
    }
  }
  @media (max-width: @screen-xs-max) {
    border-bottom: 1px solid #8fdbe9;
  }
}
.guide-mobile-wrap {
  .row {
    div {
      img {
        width:93%;
      }
      p {
        margin-top:5px;
      }
      &:nth-child(-n+3) {
        margin-bottom:10px;
      }
    }
  }
}
.home-div-1 {
  .news-wrap {
    @media (max-width: @screen-xs-max) {
      margin-top: 20px;
    }
    .nav-tabs {
      .active {
        a {
          border-radius: 0;
        }
      }
    }
  }
}
.expert-div-content-wrap{
  .media-list {
    .media {
      margin:0 10px;
      margin-bottom:5px;
      .media-object {
        max-width: 20vw;
      }
    }
    .media:first-child {
      padding-top:15px;
    }
  }
}

.cycle-slideshow {
  .slide-content {
    padding:10px 10px 0px 10px;
    p {
      margin: 10px 0 0 0;
      text-align: center;
    }
  }
}
.bottom-wrap {
  .bottom-content-wrap {
    text-align: left;
    @media (max-width: @screen-xs-max) {
      div {
        text-align: center;
      }
    }
  }
  .qrcode-wrap {
    text-align: right;
    @media (max-width: @screen-xs-max) {
      text-align: center;
    }
  }
}

.ask-online-list-content {
  .media{
    padding:10px;
    &:nth-child(2n) {
      background-color: #eee;
    }
  }
  .create-time {
    font-size: 0.9em;
    margin-bottom:4px;
  }
  .ask-content {
    margin:6px 0;
  }
  .replay-wrap {
    border: 1px solid @replay-bg-border-color;
    margin:8px 0;
    .replay-title {
      padding: 6px;
      background: @replay-bg-color;
      color: @replay-color;
      font-size: 0.9em;
    }
    .replay-content {
      padding:8px;
    }
  }
}
.btn-ask {
  color: @replay-color;
  background-color: #c1d6fc;

  border-color: #afa6de;
}
.btn-ask-wrap {
  margin-right: 7px;
  margin-top: 7px;
}
.add-form-content {
  margin: 0px 25px;
  padding: 40px 0px;
  @media (max-width: @screen-xs-max) {
    &{
      margin: 0 15px;
      padding: 10px 0;
    }
  }
}
.h-div-content-wrap {
  @media (max-width: @screen-xs-max) {
    .media {
      padding: 10px !important;
      margin:0 !important;
    }
  }
}
